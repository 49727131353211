import { AuthApi } from '@/libraries/api'

function signInByForm(userEmail, userPwd) {
  const url = '/account/sign_in/direct'
  const dt = {
    device_type: 'web',
    id: userEmail,
    pwd: userPwd
  }
  return AuthApi().post(url, dt)
}

function signInByToken() {
  const url = '/account/sign_in/by_token'
  const dt = {
    device_type: 'web'
  }
  // setUserAccessToken()
  return AuthApi().post(url, dt)
}

export default {
  signInByForm,
  signInByToken
}
