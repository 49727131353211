import * as Sentry from "@sentry/vue"
import auth from "./auth"
import SignInApi from '../api/signIn'

async function signInByForm(app, userEmail, userPassword) {
  let r = false
  try {
    const api = await SignInApi.signInByForm(userEmail, userPassword)
    if (api.data.result === true) {
      const res = api.data.data
      const tokenItem = res.authorization
      r = true
      await auth.setAccessToken(app, tokenItem)
      app.$store.dispatch('Account/setUser', res.user)
      app.$store.dispatch('Account/setUserExtra', res.user_extra)
    }
  } catch (e) {
    Sentry.captureMessage("Sign-In Error")
    Sentry.captureException(e)
    r = false
    // console.log(e)
  }
  return r
}

export default {
  signInByForm
}
