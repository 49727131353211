<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="10"
        md="4"
        class="mt-12 pt-12"
      >
        <LogoText style="width: 200px; text-align: center;" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
        md="6"
        align="center"
        class="mb-12 pb-12"
      >
        Sign in
        <SignInForm class="my-8" />
      </v-col>
      <v-col
        cols="12"
        align="center"
      >
        <!-- <GoogleLoginButton /> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogoText from '../_elements/logo/LogoText'
import SignInForm from './components/SignInForm'
// import GoogleLoginButton from '../_components/providers/ButtonGoogle'

export default {
  components: {
    LogoText,
    SignInForm
    // GoogleLoginButton
  }
}
</script>

<style lang="scss" scoped></style>
