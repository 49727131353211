<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-text-field
      ref="userEmail"
      v-model="userEmail"
      :autofocus="userEmailFocus"
      label="User ID"
      required
    />
    <v-text-field
      ref="userPassword"
      v-model="userPassword"
      label="Password"
      :type="showPasswordField ? 'text' : 'password'"
      :append-icon="showPasswordField ? 'mdi-eye' : 'mdi-eye-off'"
      required
      @click:append="showPasswordField = !showPasswordField"
      @keyup.enter="doLogin"
    />
    <v-btn
      small
      block
      large
      :loading="isLoading"
      @click="doLogin"
    >
      Sign In
    </v-btn>
  </v-form>
</template>

<script>
import Signin from '../models/signIn'

export default {
  data: () => ({
    isLoading: false,
    showPasswordField: false,
    valid: false,
    name: '',
    userEmail: '',
    userEmailFocus: true,
    userPassword: '',
    emailRules: [(v) => !!v || 'User Email is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    select: null,
    checkbox: false,
    lazy: true
  }),

  mounted() {
    this.$refs.form.reset()
    this.$refs.userEmail.$el.focus()
  },

  methods: {
    async doLogin() {
      if (this.$refs.form.validate() === false) {
        this.$nextTick(() => this.$refs.userEmail.$el.focus())
        return
      }
      this.isLoading = true
      const result = await Signin.signInByForm(this, this.userEmail, this.userPassword)
      this.isLoading = false
      if (result === true) {
        this.$router.push('/dashboard/main')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
