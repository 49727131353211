<template>
  <v-img
    :src="require('./assets/logo-text-blue.png')"
    contain
    class="mb-12 mx-auto"
    style="text-align: center;"
  />
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>
